import axios from "axios";

const ApiUrl = "https://dev-baroom-api.flynautstaging.com";

export const Axios = axios.create({
  baseURL: ApiUrl,
});

Axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      if (error.response.status === 403) {
        if (error.response.data && error.response.data.type === 2) {
          console.log("Already Registered");
        }
      } else if (
        error.response.status === 403 ||
        error.response.status === 401
      ) {
        console.log("Unauthorized Access");
      }
    }

    return Promise.reject(error);
  }
);
