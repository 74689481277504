import { Axios } from "./axios.interceptor";

const getContent = ({ type }) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/content?type=${type}`)
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};
//completed
const signInApi = ({ email, password, role }) => {
  console.log("payload".role);
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/login`, {
      email: email,
      password: password,
      role: role,
    })
      .then((result) => {
        resolve(result);
        console.log(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const subscribe = ({ email }) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/subscribe`, {
      email: email,
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const deleteAccountInt = ({ email }) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/delete-account`, {
      email: email,
    })
      .then((result) => {
        resolve(result);
        console.log(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

//completed
const signUpApi = ({
  name,
  email,
  password,
  phoneNo,
  referralCode,
  notifytoken,
  role,
  profile,
}) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("phoneNo", phoneNo);
    formData.append("referralCode", referralCode);
    formData.append("notifytoken", notifytoken);
    formData.append("role", role);
    formData.append("profile", profile);
    console.log(notifytoken);
    Axios.post(
      `/api/v1/signup`,

      // name, email phoneNo password role notifytoken
      // referal_code profile
      formData,

      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
      .then((result) => {
        resolve(result);
        console.log(result.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};
// Completed
const forgetPassApi = ({ email }) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/forget-password`, {
      userinput: email,
    })
      .then((result) => {
        resolve(result);
        console.log(result.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};
// Completed
const verifyApi = ({ refId, otp, type }) => {
  console.log("verify api function ", refId, otp, type);
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/verify`, {
      refId: refId,
      otp: otp,
      type: type,
    })
      .then((result) => {
        resolve(result);
        // console.log(result.data)
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};
// Hold
const resendOtpApi = ({ refId }) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/resendOtp`, {
      refId: refId,
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};
/// completed
const resetPassApi = ({ newPassword, cPassword, token }) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/reset-password`,
      {
        newPassword: newPassword,
        cPassword: cPassword,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const triggerBackgroundAuthentication = (token) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/background-auth`,
      {},
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const fetchBartenderDetailsApi = (token) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/bartender`, {
      headers: {
        Authorization: token,
      },
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const updateBartBasic = ({
  bio,
  name,
  yearsOfExperience,
  birthDate,
  profile,
  location,
  latitude,
  phoneNo,
  longitude,
  token,
}) => {
  return new Promise((resolve, reject) => {
    Axios.patch(
      `/api/v1/bartender/basic`,
      {
        bio,
        yearsOfExperience,
        ...(name ? { name } : {}),
        birthDate,
        profile,
        phoneNo,
        location,
        latitude,
        longitude,
      },
      {
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
        },
      }
    )
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const updateBartDesig = ({ designation, token }) => {
  return new Promise((resolve, reject) => {
    Axios.patch(
      `/api/v1/bartender/desg`,
      {
        designation,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const updateBartSkills = ({ data, token }) => {
  console.log(data);

  return new Promise((resolve, reject) => {
    Axios.patch(
      `/api/v1/bartender/skill`,
      {
        ...data,
      },
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    )
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const updateBartLinksDoc = ({ data, token }) => {
  const fdata = new FormData();
  fdata.append("portfoliolink", data.portfoliolink);
  fdata.append("linkedinlink", data.linkedinlink);
  fdata.append("instagramlink", data.instagramlink);
  fdata.append("resume", data.resume);

  data.certificates.forEach((element) => {
    fdata.append("certificates", element);
  });

  return new Promise((resolve, reject) => {
    Axios.patch(`/api/v1/bartender/linksdoc`, fdata, {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const ModifyupdateBartLinksDoc = ({ data, token, resume_keys, certi_keys }) => {
  const fdata = new FormData();
  fdata.append("portfoliolink", data.portfoliolink);
  fdata.append("linkedinlink", data.linkedinlink);
  fdata.append("instagramlink", data.instagramlink);
  fdata.append("resume", data.resume);
  fdata.append("certi_keys", JSON.stringify(certi_keys));
  fdata.append("resume_keys", JSON.stringify(resume_keys));

  data.certificates.forEach((element) => {
    fdata.append("certificates", element);
  });

  return new Promise((resolve, reject) => {
    Axios.patch(`/api/v1/bartender/linksdoc`, fdata, {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const updateBartSsn = ({ data, token }) => {
  return new Promise((resolve, reject) => {
    Axios.patch(
      `/api/v1/bartender/background`,
      {
        ...data,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const updateBartPayment = ({ data, token }) => {
  console.log(data);

  return new Promise((resolve, reject) => {
    console.log(data);

    Axios.patch(
      `/api/v1/bartender/payment`,
      {
        ...data,
      },
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    )
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const getBartPayment = ({ filter, token, search }) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/bartender/payment?filter=${filter}&search=${search}`, {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const getFriendsList = ({ search, token, page }) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/bartender/friends?search=${search}&page=${page}`, {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const getFriendsRequestSent = ({ token }) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/bartender/sent-request`, {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};
const getFriendsRequestReceived = ({ token }) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/bartender/received-request`, {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const friendRequestAction = ({ token, id, action }) => {
  return new Promise((resolve, reject) => {
    Axios.patch(
      `/api/v1/bartender/request`,
      { id, action },
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    )
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const getNearbyBaretenders = ({ search, token, page }) => {
  return new Promise((resolve, reject) => {
    Axios.get(
      `/api/v1/bartender/nearbybartender?search=${search}&page=${page}`,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    )
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const getNearbyEstablishments = ({ token, page }) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/bartender/nearbyestablishment?page=${page}`, {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const postAvailability = ({ token, day, availtime }) => {
  return new Promise((resolve, reject) => {
    Axios.patch(
      `/api/v1/bartender/datespost`,
      { day, availtime },
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    )
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const initilizeChat = ({ token, to }) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/chat`,
      { to },
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const getChatRooms = ({ token, page, search, tab }) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/chatrooms?search=${search}&page=${page}`,
      { roomType: tab.toString() },
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const getChatMessages = ({ token, page, search, chatId }) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/getmsg?&page=${page}`,
      { chatId },
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const sendChatMessages = ({ token, chatId, text }) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/sendmsg`,
      { chatId, text },
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};
const blockUser = ({ token, chatId, action }) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/block`,
      { chatId, action },
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};
const reportUser = ({ token, reported, complaint }) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/report`,
      { reported, complaint },
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const sendFriendRequest = ({ token, to }) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/bartender/request`,
      { to },
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    )
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const sendPaymentRemind = ({ eid, token }) => {
  return new Promise((resolve, reject) => {
    Axios.patch(
      `/api/v1/bartender/payreminder`,
      {
        eid,
      },
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    )
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const getCurrentGigsforBart = ({ page, search, token }) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/bartender/assignedgigs?page=${page}&search=${search}`, {
      headers: {
        Authorization: token,
      },
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const getAppliedGigsforBart = ({ page, search, token }) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/bartender/appliedgigs?page=${page}&search=${search}`, {
      headers: {
        Authorization: token,
      },
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const getNewOpportunityforBart = ({ page, search, token }) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/bartender/gigs?page=${page}&search=${search}`, {
      headers: {
        Authorization: token,
      },
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const getPastGigsforBart = ({ page, search, filter1, filter2, token }) => {
  return new Promise((resolve, reject) => {
    Axios.get(
      `/api/v1/bartender/pastgigs?page=${page}&search=${search}&filter=${filter1}&filter1=${filter2}`,
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const ApplyForGig = ({ job_id, token }) => {
  return new Promise((resolve, reject) => {
    Axios.patch(
      `/api/v1/bartender/job-apply`,
      { job_id },
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const CancleForGig = ({ job_id, token }) => {
  return new Promise((resolve, reject) => {
    Axios.patch(
      `/api/v1/bartender/job-cancle`,
      { job_id },
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const toggleBartAvailability = ({ available, token }) => {
  return new Promise((resolve, reject) => {
    Axios.patch(
      `/api/v1/bartender/update-avail`,
      {
        available,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const googleLoginapi = ({ access_token, role }) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/googlelogin`, {
      access_token,
      role,
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const appleLoginApi = (response) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/applelogin`, response, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const createEstablishmentApi = ({ data, token }) => {
  return new Promise((resolve, reject) => {
    let fd = new FormData();

    data.forEach((element, index) => {
      let keys = Object.keys(element);

      keys.forEach((elem, ind) => {
        let value = data[index][elem];
        if (value === null || value === "") {
          return;
        }
        if (index === 2 && ind === 1) {
          value = JSON.stringify(value);
        }

        console.log(elem, index, ind);
        if (index === 3 && ind === 1) {
          value.forEach((file) => {
            fd.append(elem, file);
          });
        } else {
          fd.append(elem, value);
        }
      });
    });

    Axios.post(`/api/v1/establishment/newestablishment`, fd, {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const createEstablishmentApi1 = ({ data, token }) => {
  return new Promise((resolve, reject) => {
    let fd = new FormData();
    fd.append("EprofileImg", data.EprofileImg);
    fd.append("establishmentName", data.establishmentName);
    fd.append("discription", data.discription);
    fd.append("serviceType", data.serviceType);
    fd.append("dressCode", data.dressCode);
    fd.append("posSystem", data.posSystem);
    fd.append("accommodates", data.accommodates);
    fd.append("customerFlow", data.customerFlow);
    fd.append("yearsOfExperience", data.yearsOfExperience);
    fd.append("websiteLink", data.websiteLink);
    fd.append("timeZone", data.timeZone);
    fd.append("location", JSON.stringify(data.location));
    fd.append("latitude", data.latitude);
    fd.append("longitude", data.longitude);
    data.images.forEach((img) => {
      fd.append("Images", img);
    });

    Axios.post(`/api/v1/establishment/newestablishment`, fd, {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const createEarlyRegi = ({ data }) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/early-entries`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const fetchMonthlyScheduleBart = ({ year, month, token }) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/bartender/monthschedule?year=${year}&month=${month}`, {
      headers: {
        Authorization: token,
      },
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const fetchDateScheduleBart = ({ date, token }) => {
  return new Promise((resolve, reject) => {
    console.log(date);
    Axios.get(`/api/v1/bartender/dayschedule?date=${date}`, {
      headers: {
        Authorization: token,
      },
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const fetchMonthlyScheduleEstab = ({ year, month, token }) => {
  return new Promise((resolve, reject) => {
    Axios.get(
      `/api/v1/establishment/monthschedule?year=${year}&month=${month}`,
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const fetchDateScheduleEstab = ({ date, token }) => {
  return new Promise((resolve, reject) => {
    console.log(date);
    Axios.get(`/api/v1/establishment/dayschedule?date=${date}`, {
      headers: {
        Authorization: token,
      },
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const fetchSetAvailability = ({ token }) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/bartender/datespost`, {
      headers: {
        Authorization: token,
      },
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const fetchReviewOnBartender = ({ token }) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/bartender/review`, {
      headers: {
        Authorization: token,
      },
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const submitReviewOnEstab = ({
  token,
  establishmentId,
  detail,
  overall,
  experience,
  staff,
  value,
}) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/bartender/review`,
      { establishmentId, detail, overall, experience, staff, value },
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const getfaqcontnet = ({ type }) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/content`, {
      params: {
        type,
      },
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const changepassword = ({ token, currentpassword, newPassword, cPassword }) => {
  return new Promise((resolve, reject) => {
    Axios.patch(
      `/api/v1/change-password`,
      { currentpassword, newPassword, cPassword },
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const patchnotificationsetting = ({ token, value, category }) => {
  return new Promise((resolve, reject) => {
    Axios.patch(
      `/api/v1/notificationsetting`,
      { value, category },
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const getnotificationsetting = ({ token }) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/notificationsetting`, {
      headers: {
        Authorization: token,
      },
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const fetchCommunityFigure = ({ token }) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/bartender/community-figure`, {
      headers: {
        Authorization: token,
      },
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const fetchUserData = ({ token }) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/bartender/`, {
      headers: {
        Authorization: token,
      },
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const RequestReviewNotify = ({ token, establishmentId }) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/bartender/requestreview`,
      { establishmentId },
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const PatchBarProfileImg = ({ token, profileImg }) => {
  return new Promise((resolve, reject) => {
    const formdata = new FormData();
    formdata.append("profile", profileImg);
    Axios.patch(`/api/v1/profileImg`, formdata, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: token,
      },
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const FetchEstabCurrentGigs = ({ token, page, search }) => {
  return new Promise((resolve, reject) => {
    Axios.get(
      `/api/v1/establishment/fetch-currentGigs?page=${page}&search=${search}`,
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const FetchPendingAcceptanceGig = ({ token, page, search }) => {
  return new Promise((resolve, reject) => {
    Axios.get(
      `/api/v1/establishment/fetch-pendingigs?page=${page}&search=${search}`,
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const FetchPastGigs = ({ token, page, search }) => {
  return new Promise((resolve, reject) => {
    Axios.get(
      `/api/v1/establishment/fetch-pastGigs?page=${page}&search=${search}`,
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const BartenderAvailTodayApi = ({ token, page, search }) => {
  return new Promise((resolve, reject) => {
    Axios.get(
      `/api/v1/establishment/fetch-availbars?page=${page}&search=${search}`,
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const getNearbyBaretendersEstab = ({ search, token, page }) => {
  return new Promise((resolve, reject) => {
    Axios.get(
      `/api/v1/establishment/nearbybartender?search=${search}&page=${page}`,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    )
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const fetchReviewOnEstablishment = ({ token }) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/establishment/review`, {
      headers: {
        Authorization: token,
      },
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const postJob = ({ token, jobdata }) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/establishment/post-job`, jobdata, {
      headers: {
        Authorization: token,
      },
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const fetchAllEstab = ({ token }) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/establishment/`, {
      headers: {
        Authorization: token,
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const fetchEstablishmentDetails = ({ token, eid }) => {
  return new Promise((resolve, reject) => {
    Axios.get(
      `/api/v1/establishment/fetchestablishment?establishmentid=${eid}`,
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const updateEstablishmentBasic = ({ token, formdata }) => {
  return new Promise((resolve, reject) => {
    Axios.patch(`/api/v1/establishment/establishment`, formdata, {
      headers: {
        Authorization: token,
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const updateEstablishmentMore = ({ token, formdata }) => {
  return new Promise((resolve, reject) => {
    Axios.patch(`/api/v1/establishment/mestablishment`, formdata, {
      headers: {
        Authorization: token,
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const getEstabPayment = ({ filter, token, search }) => {
  return new Promise((resolve, reject) => {
    Axios.get(
      `/api/v1/establishment/payment?filter=${filter}&search=${search}`,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    )
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const updateEstabBasic = ({ token, user }) => {
  return new Promise((resolve, reject) => {
    Axios.patch(`/api/v1/establishment/userdetails`, user, {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const updateEstabLocation = ({
  token,
  location,
  eid,
  timeZone,
  latitude,
  longitude,
}) => {
  return new Promise((resolve, reject) => {
    Axios.patch(
      `/api/v1/establishment/location`,
      { location, eid, timeZone, latitude, longitude },
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    )
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const updateEstabImages = ({ token, formData }) => {
  return new Promise((resolve, reject) => {
    Axios.patch(`/api/v1/establishment/images`, formData, {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const fetchAllCards = ({ token }) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/establishment/card`, {
      headers: {
        Authorization: token,
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const AddNewCards = ({ token, cToken }) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/establishment/card`,
      { cToken },
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    )
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const DeleteCard = ({ token, cardId }) => {
  return new Promise((resolve, reject) => {
    const config = {
      data: { cardId: cardId },
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    Axios.delete(`/api/v1/establishment/card`, config)
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const makePayment = ({ token, B_Id, Job_Id, cardId }) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/establishment/pay_details`,
      { B_Id, Job_Id, cardId },
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    )
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const GetPaymentDetails = ({ token, B_Id, Job_Id }) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/establishment/pay_details`, {
      params: {
        bid: B_Id,
        jobId: Job_Id,
      },
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const fetchGigDetails = ({ token, Job_Id }) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/job_details?jobId=${Job_Id}`, {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const fetchEstabbGigDetails = ({ token, Job_Id }) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/establishment/job_details?jobId=${Job_Id}`, {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const JobActionBartender = ({ token, jobId, bId, action }) => {
  return new Promise((resolve, reject) => {
    Axios.patch(
      `/api/v1/establishment/assignbartender`,
      { jobId: jobId, bid: bId, action: action },
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    )
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const FetchBartenderDetails = ({ token, uid }) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/establishment/fetch-bartenderId`,
      { uid },
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    )
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const FetchNotification = ({ token, page }) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/notification?page=${page}`, {
      headers: {
        Authorization: token,
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const UpdateNoitfyToken = ({ token, notifytoken }) => {
  return new Promise((resolve, reject) => {
    Axios.patch(
      `/api/v1/notifytoken`,
      { notifytoken },
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const LogoutUser = ({ token, notifytoken }) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/logout`,
      { notifytoken },
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const fetchTicket = ({ token }) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/ticket`, {
      headers: {
        Authorization: token,
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const postNewTicket = ({ token, data }) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/ticket`, data, {
      headers: {
        Authorization: token,
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const getReverseGeoCode = ({ token, latitude, longitude }) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/reversegeocode?lat=${latitude}&lng=${longitude}`, {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const prevworkedData = ({ token, eid }) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/bartender/previousworked`,
      { eid },
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    )
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

const FriendsDetails = ({ token, bid }) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/bartender/bartenderfriends`,
      { bid },
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    )
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err.response.data);
      });
  });
};

export {
  getContent,
  signInApi,
  signUpApi,
  forgetPassApi,
  verifyApi,
  resendOtpApi,
  resetPassApi,
  triggerBackgroundAuthentication,
  googleLoginapi,
  appleLoginApi,
  changepassword,
  patchnotificationsetting,
  getnotificationsetting,
  PatchBarProfileImg,
  FetchNotification,
  UpdateNoitfyToken,
  LogoutUser,
  postNewTicket,
  fetchTicket,
  prevworkedData,
  FriendsDetails,
  // Bartender
  fetchBartenderDetailsApi,
  updateBartBasic,
  updateBartDesig,
  updateBartSkills,
  updateBartLinksDoc,
  updateBartSsn,
  updateBartPayment,
  getCurrentGigsforBart,
  getAppliedGigsforBart,
  getNewOpportunityforBart,
  getPastGigsforBart,
  toggleBartAvailability,
  fetchMonthlyScheduleBart,
  getBartPayment,
  sendPaymentRemind,
  getFriendsList,
  getNearbyBaretenders,
  sendFriendRequest,
  getFriendsRequestSent,
  getFriendsRequestReceived,
  friendRequestAction,
  getNearbyEstablishments,
  fetchDateScheduleBart,
  fetchSetAvailability,
  fetchReviewOnBartender,
  RequestReviewNotify,
  fetchCommunityFigure,
  postAvailability,
  fetchUserData,
  ApplyForGig,
  CancleForGig,
  submitReviewOnEstab,
  getfaqcontnet,
  fetchEstabbGigDetails,
  // Establishment
  createEstablishmentApi,
  createEstablishmentApi1,
  FetchEstabCurrentGigs,
  FetchPendingAcceptanceGig,
  FetchPastGigs,
  BartenderAvailTodayApi,
  fetchDateScheduleEstab,
  fetchMonthlyScheduleEstab,
  getNearbyBaretendersEstab,
  fetchReviewOnEstablishment,
  fetchAllEstab,
  postJob,
  getEstabPayment,
  updateEstabBasic,
  fetchEstablishmentDetails,
  updateEstablishmentBasic,
  updateEstablishmentMore,
  getReverseGeoCode,
  updateEstabLocation,
  updateEstabImages,
  fetchAllCards,
  AddNewCards,
  DeleteCard,
  makePayment,
  GetPaymentDetails,
  fetchGigDetails,
  JobActionBartender,
  FetchBartenderDetails,
  // Chat
  getChatRooms,
  getChatMessages,
  sendChatMessages,
  initilizeChat,
  blockUser,
  reportUser,
  createEarlyRegi,
  ModifyupdateBartLinksDoc,
  deleteAccountInt,
  // Subscribe
  subscribe,
};
